import { useEffect, useState } from "react";
import logo from "../images/logo.svg";
import { useNavigate } from "react-router-dom";
import { getItemList } from "../utils";

function Home() {
  const [list, setList] = useState([]);
  const [activePopup, setActivePopup] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const res = await getItemList();
        setList(res.data);
      } catch (error) {
        console.error("Error fetching item list:", error);
      }
    };
    
    fetchItems();
  }, []);

  const handlePopUpClick = (popupId) => {
    console.log(popupId);
    setActivePopup((prevPopupId) => (prevPopupId === popupId ? null : popupId));
  };

  return (
    <div className="App">
      <section className="main-sec">
        <header className="header">
          <img src={logo} alt="logo" />
          <hr />
          <a onClick={() => navigate("/video")} className="h1">
            Guide
          </a>
        </header>
        <div className="main-block">
          {list.map((item) => (
            <div className="video-block" key={item.id}>
              <div className="img-block" onClick={() => handlePopUpClick(item.id)}>
                <a href="#!">
                  <img src={item.image} alt={item.name} />
                </a>
              </div>
              <div className={`popup-block ${activePopup === item.id ? "active" : ""}`}>
                {item.links.map((link) => (
                  <a href={link.url} target="_blank" rel="noopener noreferrer" key={link.url}>
                    {link.name}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Home;
