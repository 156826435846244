import logo from "../images/logo.svg";
import HeroVideo from "../images/Hero-Video.mp4";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getGuideList } from "../utils";

function Video() {
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    getGuideList()
      .then((result) => {
        setVideos(result.data);
      })
      .catch((err) => {});
  }, []);

  return (
    <section className="main-sec">
      <header className="header">
        <img src={logo} alt="logo" />
        <hr />
        <a onClick={() => navigate("/")} className="h1">
          Back
        </a>
      </header>
      {videos.map((item) => {
        return <div className="main-block-video">
          <span>
            {item.name} <hr />
          </span>
          <div className="video-block ">
            <div className="video-sec">
              <div className="video-wrapper">
                <video preload="metadata" controls id="video">
                  <source src={item.url} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>;
      })}
    </section>
  );
}

export default Video;
